import { userMigrationReducer } from './reducer'
import React, { useReducer } from 'react'
import { InitialState } from './state'
import { UserMigrationContextProvider } from './UserMigrationContextProvider'
import { client, queryClient } from '@local/api/client'
import { ApolloProvider } from '@apollo/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { Routes } from './routes'
import { BrowserRouter } from 'react-router-dom'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { TranslationProvider } from '@local/translations'

export function App() {
  const [state, dispatch] = useReducer(userMigrationReducer, {
    type: 'initial-state'
  } as InitialState)

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <UserMigrationContextProvider state={state} dispatch={dispatch}>
          <BrowserRouter>
            <SnackBarProvider>
              <TranslationProvider>
                <Routes />
              </TranslationProvider>
            </SnackBarProvider>
          </BrowserRouter>
        </UserMigrationContextProvider>
      </QueryClientProvider>
    </ApolloProvider>
  )
}
