import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Account } from './Account'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { ToastEmail } from '../state'
import { useTranslation } from '@local/translations'
import { PageInfoList } from '@toasttab/buffet-pui-wizard-templates'

export const ToastAccount = () => {
  const { email, canChangeEmail } = useUserMigrationState<ToastEmail>()
  const { t } = useTranslation()

  return (
    <Account
      source='TOAST'
      email={email}
      canChangeEmail={canChangeEmail}
      heading={t('toastAccount.heading')}
      body={t('toastAccount.body')}
      questions={canChangeEmail && <Questions />}
    />
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <PageInfoList
      items={[
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('toastAccount.question1'),
          description: t('toastAccount.answer1')
        },
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('toastAccount.question2'),
          description: t('toastAccount.answer2')
        }
      ]}
    />
  )
}
