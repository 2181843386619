const deferMigration = async (
  returnUrl: string | null
): Promise<Response | null> => {
  const url = returnUrl
    ? `/DeferMigration?returnUrl=${encodeURIComponent(returnUrl)}`
    : '/DeferMigration'
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return resp
}

export default deferMigration
