import { graphql } from '../generated/gql'

export const UserMigrationDefaults = graphql(/* GraphQL */ `
  query userMigrationDefaults {
    userMigration {
      defaults {
        __typename
        ... on UserMigrationDefaults {
          email
          source
          canChangeEmail
        }
        ... on UserMigrationUserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationNotEnabled {
          message
        }
        ... on UserMigrationUserNotFound {
          userId
        }
      }
    }
  }
`)

export const SendAccountVerificationEmail = graphql(/* GraphQL */ `
  mutation sendAccountVerificationEmail(
    $email: String!
    $idempotencyKey: String!
    $source: EmailVerificationSourceType
  ) {
    userMigration {
      sendAccountVerificationEmail(
        email: $email
        idempotencyKey: $idempotencyKey
        source: $source
      ) {
        __typename
        ... on UserMigrationVerificationEmailSent {
          email
          eventTime
          idempotencyKey
          expirationTime
        }
        ... on UserMigrationUserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationNotEnabled {
          message
        }
        ... on UserMigrationUserNotFound {
          userId
        }
        ... on UserMigrationEmailExistsInCustomer {
          customerId
        }
        ... on UserMigrationToastUserHasCredentials {
          toastIdentityGuid
        }
        ... on UserMigrationChooseAnotherUsername {
          message
        }
        ... on UserMigrationSendAccountVerificationEmailFailed {
          error {
            ... on EmailHasBeenUpdated {
              message
            }
            ... on EmailAlreadyVerified {
              message
            }
            ... on KeyDoesNotMatch {
              message
            }
            ... on DuplicateRequest {
              message
            }
            ... on EmailVerificationFailed {
              message
            }
          }
        }
        ... on UserMigrationFailed {
          message
        }
      }
    }
  }
`)

export const VerifyAccountEmail = graphql(/* GraphQL */ `
  query verifyAccountEmail($email: String!) {
    userMigration {
      verifyAccountEmail(email: $email) {
        __typename
        ... on UserMigrationEmailVerified {
          toastIdentityGuid
          verifiedTime
          toastAccountType
        }
        ... on UserMigrationUserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationNotEnabled {
          message
        }
        ... on UserMigrationUserNotFound {
          userId
        }
        ... on UserMigrationEmailExistsInCustomer {
          customerId
        }
        ... on UserMigrationToastUserHasCredentials {
          toastIdentityGuid
        }
        ... on UserMigrationChooseAnotherUsername {
          message
        }
        ... on UserMigrationEmailNotVerified {
          toastIdentityGuid
          toastAccountType
        }
        ... on UserMigrationFailed {
          message
        }
      }
    }
  }
`)

export const ConfirmAccountVerificationEmail = graphql(/* GraphQL */ `
  mutation confirmAccountVerificationEmail(
    $email: String!
    $confirmationKey: String!
  ) {
    userMigration {
      confirmAccountVerificationEmail(
        email: $email
        confirmationKey: $confirmationKey
      ) {
        __typename
        ... on UserMigrationEmailVerified {
          toastIdentityGuid
          verifiedTime
          toastAccountType
        }
        ... on UserMigrationUserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationNotEnabled {
          message
        }
        ... on UserMigrationUserNotFound {
          userId
        }
        ... on UserMigrationEmailExistsInCustomer {
          customerId
        }
        ... on UserMigrationToastUserHasCredentials {
          toastIdentityGuid
        }
        ... on UserMigrationChooseAnotherUsername {
          message
        }
        ... on UserMigrationEmailConfirmationFailed {
          error {
            ... on EmailHasBeenUpdated {
              message
            }
            ... on EmailAlreadyVerified {
              message
            }
            ... on KeyDoesNotMatch {
              message
            }
            ... on EmailVerificationFailed {
              message
            }
          }
        }
        ... on UserMigrationFailed {
          message
        }
      }
    }
  }
`)

export const Migrate = graphql(/* GraphQL */ `
  mutation migrate($email: String!, $password: String!) {
    userMigration {
      migrate(email: $email, password: $password) {
        __typename
        ... on UserMigrationChooseAnotherUsername {
          message
        }
        ... on UserMigrationEmailExistsInCustomer {
          customerId
        }
        ... on UserMigrationEmailNotVerified {
          toastIdentityGuid
          toastAccountType
        }
        ... on UserMigrationFailed {
          message
        }
        ... on UserMigrationNotEnabled {
          message
        }
        ... on UserMigrationSuccess {
          email
          toastIdentityGuid
          userId
        }
        ... on UserMigrationToastUserHasCredentials {
          toastIdentityGuid
        }
        ... on UserMigrationUserAlreadyMigrated {
          userId
          toastIdentityGuid
        }
        ... on UserMigrationUserNotFound {
          userId
        }
      }
    }
  }
`)
