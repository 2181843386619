import * as React from 'react'
import { Layout } from '../layout'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { EmailVerified as EmailVerifiedState } from '../state'
import { useTranslation } from '@local/translations'
import {
  ContentHeader,
  PageAside,
  PageBreak,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'

export const EmailVerified = () => {
  const state = useUserMigrationState<EmailVerifiedState>()
  const { t } = useTranslation()

  return (
    <Layout>
      <PageMain>
        <ContentHeader title={t('email.verified.emailVerified')}>
          <span className='font-bold' data-testid='email'>
            {state.email}
          </span>{' '}
          {t('email.verified.isVerified')}
          <PageBreak />
        </ContentHeader>
      </PageMain>
      <PageAside></PageAside>
    </Layout>
  )
}
