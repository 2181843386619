import { MigrationHeader } from './MigrationHeader'
import {
  Page,
  PageBody,
  PageContainer
} from '@toasttab/buffet-pui-wizard-templates'
import { useCompanyCode } from '@toasttab/ec-session'
import * as React from 'react'

export const Layout = (props: { children: React.ReactNode }) => {
  const { children } = props
  const companyCode = useCompanyCode()

  return (
    <Page>
      <MigrationHeader companyCode={companyCode} />
      <PageContainer>
        <PageBody>{children}</PageBody>
      </PageContainer>
    </Page>
  )
}
