import * as React from 'react'
import { useCallback } from 'react'
import { Migrate } from '@local/api/documents'
import { WaitForPassword } from '../state'
import { useUserMigration } from '../UserMigrationContextProvider'
import { PasswordView } from './PasswordView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { useUser } from '@toasttab/ec-session'
import { logout } from '../redirects'

export const Password = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserMigration<WaitForPassword>()
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()
  const user = useUser()

  const migrate = useCallback(
    async (password: string) => {
      if (password) {
        const migrateMutation = async () => {
          const result = await client.mutate({
            mutation: Migrate,
            variables: {
              email: state.email,
              password: password
            },
            fetchPolicy: 'network-only'
          })

          const migration = result.data?.userMigration?.migrate

          switch (migration?.__typename) {
            case 'UserMigrationSuccess':
              dispatch({
                type: 'migrated',
                email: migration.email,
                toastIdentityGuid: migration.toastIdentityGuid,
                userId: migration.userId
              })
              break
            case 'UserMigrationUserAlreadyMigrated':
              dispatch({
                type: 'migrated',
                userId: migration?.userId,
                toastIdentityGuid: migration?.toastIdentityGuid,
                email: user.email
              })
              break
            case 'UserMigrationNotEnabled':
              logout()
              break
            case 'UserMigrationUserNotFound':
              showErrorSnackBar(t('anErrorOccurred'))
              dispatch({
                type: 'restart'
              })
              break
            case 'UserMigrationChooseAnotherUsername':
            case 'UserMigrationEmailExistsInCustomer':
              dispatch({
                type: 'choose-another-email',
                email: state.email
              })
              break
            case 'UserMigrationToastUserHasCredentials':
            case 'UserMigrationEmailNotVerified':
              dispatch({
                type: 'restart'
              })
              break
            case 'UserMigrationFailed':
            default:
              showErrorSnackBar(t('anErrorOccurred'))
              break
          }
        }
        migrateMutation()
      }
    },
    [state.email, user.email, client, dispatch, showErrorSnackBar]
  )

  return <PasswordView onContinue={migrate} />
}
