import {
  cdnTranslationLoaderFactory,
  createTranslationUtilities,
  TranslationLoader
} from '@toasttab/buffet-pui-translation-utilities'
import { useIntlProps } from 'banquet-runtime-modules'
import * as React from 'react'
import { translations } from './locales/en_US'

export type Translations = typeof translations

const defaultStrings = translations

export type AppStrings = typeof defaultStrings

const spaName = 'team-user-migration-spa'

export const translationLoader: TranslationLoader = async (language) => {
  const fallback = { translations }
  let local
  if (language !== 'en-US') {
    switch (language) {
      case 'es-US':
        local = await import(/* webpackChunkName: "es-US" */ './locales/es_US')
        break
      default:
        local = undefined
    }
  }
  return { local, fallback }
}

const { useTranslation, BuffetTranslationProvider } =
  createTranslationUtilities<Translations>({
    spaName,
    translationLoader
  })

const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18next, cdnUrl } = useIntlProps()

  return (
    <BuffetTranslationProvider i18n={i18next} cdnUrl={cdnUrl}>
      {children}
    </BuffetTranslationProvider>
  )
}

export { useTranslation, TranslationProvider }
