import * as React from 'react'
import { useEffect } from 'react'
import { useUserMigration } from '../UserMigrationContextProvider'
import {
  SendAccountVerificationEmail,
  VerifyAccountEmail
} from '@local/api/documents'
import { WaitForEmailVerification } from '../state'
import { VerifyEmailView } from './VerifyEmailView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { useUser } from '@toasttab/ec-session'
import { logout } from '../redirects'

const verificationInterval = 3000

export const VerifyEmail = () => {
  const { state, dispatch } = useUserMigration<WaitForEmailVerification>()
  const [verificationTimeout, setVerificationTimeout] = React.useState(0)
  const client = useApolloClient()
  const { showErrorSnackBar, showSnackBar } = useSnackBar()
  const [checkEmailVerificationCounter, setCheckEmailVerificationCounter] =
    React.useState(0)
  const { t } = useTranslation()
  const user = useUser()

  const checkAccountEmailVerification = async () => {
    const result = await client.query({
      query: VerifyAccountEmail,
      variables: {
        email: state.email
      },
      fetchPolicy: 'network-only'
    })

    const queryResult = result.data?.userMigration?.verifyAccountEmail

    switch (queryResult?.__typename) {
      case 'UserMigrationEmailVerified':
        dispatch({
          type: 'email-verified',
          email: state.email
        })
        return false
      case 'UserMigrationUserAlreadyMigrated':
        dispatch({
          type: 'migrated',
          userId: queryResult?.userId,
          toastIdentityGuid: queryResult?.toastIdentityGuid,
          email: user.email
        })
        return false
      case 'UserMigrationNotEnabled':
        logout()
        return true
      case 'UserMigrationChooseAnotherUsername':
      case 'UserMigrationEmailExistsInCustomer':
        dispatch({
          type: 'choose-another-email',
          email: state.email
        })
        return false
      case 'UserMigrationToastUserHasCredentials':
        dispatch({
          type: 'restart'
        })
        return true
      case 'UserMigrationEmailNotVerified':
        return true
      case 'UserMigrationFailed':
        showErrorSnackBar(t('anErrorOccurredRetrying'))
        return true
      case 'UserMigrationUserNotFound':
      default:
        showErrorSnackBar(t('anErrorOccurred'))
        dispatch({
          type: 'restart'
        })
        return true
    }
  }

  useEffect(() => {
    const checkVerification = async () => {
      clearTimeout(verificationTimeout)
      const shouldContinue = await checkAccountEmailVerification()
      if (shouldContinue) {
        const timeout = setTimeout(
          () => setCheckEmailVerificationCounter((value) => value + 1),
          verificationInterval
        ) as unknown as number
        setVerificationTimeout(timeout)
      }
    }
    checkVerification()
    return () => {
      clearTimeout(verificationTimeout)
    }
  }, [checkEmailVerificationCounter])

  const handleEditEmail = () => {
    dispatch({
      type: 'migration-defaults',
      email: state.email,
      source: state.source,
      canChangeEmail: true
    })
  }

  const handleResendEmail = async () => {
    const idempotencyKey = crypto.randomUUID()
    const result = await client.mutate({
      mutation: SendAccountVerificationEmail,
      variables: {
        email: state.email,
        idempotencyKey: idempotencyKey,
        source: 'PAYROLL'
      }
    })

    const mutationResult =
      result.data?.userMigration?.sendAccountVerificationEmail

    switch (mutationResult?.__typename) {
      case 'UserMigrationVerificationEmailSent':
        showSnackBar(`${t('email.verify.emailResentTo')} ${state.email}`)
        break
      case 'UserMigrationUserAlreadyMigrated':
        dispatch({
          type: 'migrated',
          userId: mutationResult?.userId,
          toastIdentityGuid: mutationResult?.toastIdentityGuid,
          email: user.email
        })
        break
      case 'UserMigrationNotEnabled':
        logout()
        break
      case 'UserMigrationUserNotFound':
        showErrorSnackBar(t('anErrorOccurred'))
        dispatch({
          type: 'restart'
        })
        break
      case 'UserMigrationChooseAnotherUsername':
      case 'UserMigrationEmailExistsInCustomer':
        dispatch({
          type: 'choose-another-email',
          email: state.email
        })
        break
      case 'UserMigrationToastUserHasCredentials':
        dispatch({
          type: 'restart'
        })
        break
      case 'UserMigrationSendAccountVerificationEmailFailed':
        showErrorSnackBar(t('problemSendingEmail'))
        break
      case 'UserMigrationFailed':
      default:
        showErrorSnackBar(t('anErrorOccurred'))
        break
    }
  }

  return (
    <VerifyEmailView
      email={state.email}
      onEditEmail={handleEditEmail}
      onResendEmail={handleResendEmail}
    />
  )
}
