import * as React from 'react'
import { useCallback } from 'react'
import { SendAccountVerificationEmail } from '@local/api/documents'
import { useUserMigration } from '../UserMigrationContextProvider'
import { EmailConfirmationError as EmailConfirmationErrorState } from '../state'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { EmailConfirmationErrorView } from './EmailConfirmationErrorView'
import { useTranslation } from '@local/translations'
import { useUser } from '@toasttab/ec-session'
import { logout } from '../redirects'

export const EmailConfirmationError = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserMigration<EmailConfirmationErrorState>()
  const { email } = state
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()
  const [isSending, setIsSending] = React.useState(false)
  const user = useUser()

  const resendEmail = useCallback(() => {
    const sendEmail = async () => {
      if (email !== undefined && email != null) {
        setIsSending(true)
        const idempotencyKey = crypto.randomUUID()
        const result = await client.mutate({
          mutation: SendAccountVerificationEmail,
          variables: {
            email: email,
            idempotencyKey: idempotencyKey
          }
        })

        const mutationResult =
          result.data?.userMigration?.sendAccountVerificationEmail

        setIsSending(false)

        switch (mutationResult?.__typename) {
          case 'UserMigrationVerificationEmailSent':
            dispatch({
              type: 'account-verification-email-sent',
              source: 'NEW',
              email: mutationResult.email,
              idempotencyKey: idempotencyKey
            })
            break
          case 'UserMigrationUserAlreadyMigrated':
            dispatch({
              type: 'migrated',
              userId: mutationResult?.userId,
              toastIdentityGuid: mutationResult?.toastIdentityGuid,
              email: user.email
            })
            break
          case 'UserMigrationNotEnabled':
            logout()
            break
          case 'UserMigrationUserNotFound':
            showErrorSnackBar(t('anErrorOccurred'))
            dispatch({
              type: 'restart'
            })
            break
          case 'UserMigrationChooseAnotherUsername':
          case 'UserMigrationEmailExistsInCustomer':
            dispatch({
              type: 'choose-another-email',
              email: state.email
            })
            break
          case 'UserMigrationToastUserHasCredentials':
            dispatch({
              type: 'restart'
            })
            break
          case 'UserMigrationSendAccountVerificationEmailFailed':
            showErrorSnackBar(t('problemSendingEmail'))
            break
          case 'UserMigrationFailed':
          default:
            showErrorSnackBar(t('anErrorOccurred'))
            break
        }
      }
    }
    sendEmail()
  }, [])

  return (
    <EmailConfirmationErrorView
      onResendEmail={resendEmail}
      isSending={isSending}
    />
  )
}
