import React, { useEffect } from 'react'
import { pathTemplate, useParamsFromRoute } from '../routes'
import { ConfirmAccountVerificationEmail } from '@local/api/documents'
import { useUserMigration } from '../UserMigrationContextProvider'
import { InitialState } from '../state'
import { Navigate } from 'react-router-dom'
import { ConfirmEmailView } from './ConfirmEmailView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { useUser } from '@toasttab/ec-session'
import { logout } from '../redirects'

export const ConfirmEmail = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserMigration<InitialState>()
  const { email, confirmationId } = useParamsFromRoute('confirmEmail')
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()
  const user = useUser()

  useEffect(() => {
    if (!email || !confirmationId) {
      return
    }
    const fetchData = async () => {
      const result = await client.mutate({
        mutation: ConfirmAccountVerificationEmail,
        variables: {
          email: email,
          confirmationKey: confirmationId
        },
        fetchPolicy: 'network-only'
      })

      const verification =
        result.data?.userMigration?.confirmAccountVerificationEmail

      switch (verification?.__typename) {
        case 'UserMigrationEmailVerified':
          dispatch({
            type: 'email-confirmed',
            email: email
          })
          break
        case 'UserMigrationUserAlreadyMigrated':
          dispatch({
            type: 'migrated',
            userId: verification?.userId,
            toastIdentityGuid: verification?.toastIdentityGuid,
            email: user.email
          })
          break
        case 'UserMigrationNotEnabled':
          logout()
          break
        case 'UserMigrationUserNotFound':
          showErrorSnackBar(t('anErrorOccurred'))
          dispatch({
            type: 'restart'
          })
          break
        case 'UserMigrationChooseAnotherUsername':
        case 'UserMigrationEmailExistsInCustomer':
          dispatch({
            type: 'choose-another-email',
            email: email
          })
          break
        case 'UserMigrationToastUserHasCredentials':
          dispatch({
            type: 'restart'
          })
          break
        case 'UserMigrationEmailConfirmationFailed':
          dispatch({
            type: 'email-confirmation-error',
            email: email
          })
          break
        case 'UserMigrationFailed':
        default:
          showErrorSnackBar(t('anErrorOccurred'))
          break
      }
    }
    fetchData()
  }, [email, confirmationId, client, dispatch, showErrorSnackBar, t])

  if (state.type === 'initial-state') {
    return <ConfirmEmailView />
  } else {
    return <Navigate to={pathTemplate('index')} state={state} />
  }
}
