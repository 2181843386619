import * as React from 'react'
import { useCallback } from 'react'
import { useUserMigration } from '../UserMigrationContextProvider'
import { EmailConfirmed as EmailConfirmedState } from '../state'
import { EmailConfirmedView } from './EmailConfirmedView'

export const EmailConfirmed = () => {
  const { state, dispatch } = useUserMigration<EmailConfirmedState>()
  const { email } = state

  const onContinue = useCallback(() => {
    dispatch({
      type: 'wait-for-password',
      email: email
    })
  }, [dispatch, email])

  return <EmailConfirmedView email={email} onContinue={onContinue} />
}
