import { routeFactory } from '@local/react-router-extensions/route-factory'

const base = `/user/migration`

const {
  pathTemplate,
  LinkToRoute,
  routeToPath,
  useRouteToPath,
  useParamsFromRoute
} = routeFactory({
  index: `${base}`,
  confirmEmail: `${base}/confirmations/:email/:confirmationId`
})

export {
  pathTemplate,
  LinkToRoute,
  routeToPath,
  useRouteToPath,
  useParamsFromRoute
}
