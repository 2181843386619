import {
  generatePath,
  Link,
  LinkProps,
  PathParam,
  useParams,
  URLSearchParamsInit,
  useNavigate,
  createSearchParams,
  resolvePath,
  NavLink,
  NavLinkProps,
  NavigateProps,
  Navigate
} from 'react-router-dom'

type TParams<TPath extends string> = {
  [key in PathParam<TPath>]: string | null
}

/**
 * All path should be fully resolved:
 *
 * @example
 * {
 *  'account.overview': '/account/:client/overview'
 * }
 * @param routeTable
 * @returns
 */
function routeFactory<
  TRoute extends string,
  TPath extends `/${string}`,
  TRouteTable extends Record<TRoute, TPath>
>(routeTable: TRouteTable) {
  /**
   * Given a route, it's route params and the provided search params build a path
   *
   * @param route
   * @param routeParams
   * @param searchParams
   * @returns
   */
  function routeToPath<TRoute extends keyof TRouteTable>(
    route: TRoute,
    routeParams: TParams<TRouteTable[TRoute]>,
    searchParams?: URLSearchParamsInit
  ) {
    const { pathname, search } = resolvePath({
      pathname: pathTemplate(route),
      search: createSearchParams(searchParams).toString()
    })

    return generatePath(pathname, routeParams) + search
  }

  /**
   * Given a route, it's route params and the provided search params build a path.
   *
   * This also auto merges the current params if you don't want to include some
   * @param route
   * @param routeParams
   * @param searchParams
   * @returns
   */
  function useRouteToPath<
    TRoute extends keyof TRouteTable,
    TRouteParams extends TParams<TRouteTable[TRoute]>
  >(
    route: TRoute,
    routeParams?: Partial<TRouteParams>,
    searchParams?: URLSearchParamsInit
  ) {
    const currentParams = useParams()

    const params = {
      ...currentParams,
      ...routeParams
    } as unknown as TRouteParams

    return routeToPath(route, params, searchParams)
  }

  /**
   *
   * @param props
   * @returns
   */
  function LinkToRoute<
    TRoute extends keyof TRouteTable,
    TRouteParams extends TParams<TRouteTable[TRoute]>
  >(
    props: {
      route: TRoute
      routeParams?: Partial<TRouteParams>
      searchParams?: URLSearchParamsInit
    } & Omit<LinkProps, 'to'>
  ) {
    const { route, routeParams, searchParams, children, ...linkProps } = props

    const path = useRouteToPath(route, routeParams, searchParams)

    return (
      <Link to={path} {...linkProps}>
        {children}
      </Link>
    )
  }

  function NavLinkToRoute<
    TRoute extends keyof TRouteTable,
    TRouteParams extends TParams<TRouteTable[TRoute]>
  >(
    props: {
      route: TRoute
      routeParams?: Partial<TRouteParams>
      searchParams?: URLSearchParamsInit
    } & Omit<NavLinkProps, 'to'>
  ) {
    const { route, routeParams, searchParams, children, ...navLinkProps } =
      props

    const path = useRouteToPath<TRoute, TRouteParams>(
      route,
      routeParams,
      searchParams
    )

    return (
      <NavLink to={path} {...navLinkProps}>
        {children}
      </NavLink>
    )
  }

  function NavigateToRoute<
    TRoute extends keyof TRouteTable,
    TRouteParams extends TParams<TRouteTable[TRoute]>
  >(
    props: {
      route: TRoute
      routeParams?: Partial<TRouteParams>
      searchParams?: URLSearchParamsInit
    } & Omit<NavigateProps, 'to'>
  ) {
    const { route, routeParams, searchParams, ...navigateProps } = props

    const path = useRouteToPath<TRoute, TRouteParams>(
      route,
      routeParams,
      searchParams
    )

    return <Navigate to={path} {...navigateProps} />
  }

  function pathTemplate<TRoute extends keyof TRouteTable>(route: TRoute) {
    return routeTable[route]
  }

  function useParamsFromRoute<
    TRoute extends keyof TRouteTable,
    TRouteParams extends TParams<TRouteTable[TRoute]>
  >(
    /**
     * This is used to properly type the params
     */
    _?: TRoute
  ) {
    return useParams() as TRouteParams
  }

  function useNavigateToRoute<
    TRoute extends keyof TRouteTable,
    TRouteParams extends TParams<TRouteTable[TRoute]>
  >() {
    const navigate = useNavigate()

    return (
      route: TRoute,
      routeParams: TRouteParams,
      searchParams?: URLSearchParamsInit
    ) => navigate(routeToPath(route, routeParams, searchParams))
  }

  return {
    routeTable,
    useParamsFromRoute,
    useNavigateToRoute,
    useRouteToPath,
    NavigateToRoute,
    routeToPath,
    pathTemplate,
    LinkToRoute,
    NavLinkToRoute
  }
}

export { routeFactory }
