import { TextInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { useTranslation } from '@local/translations'
import { useFormikContext } from 'formik'
import { EmailFormValues } from '../account/AccountView'
import { Panel } from '@toasttab/buffet-pui-config-templates'

export const EmailCard = (props: {
  email?: string
  canChangeEmail: boolean
}) => {
  const { email, canChangeEmail } = props
  const { t } = useTranslation()
  const { errors, handleChange, handleBlur } =
    useFormikContext<EmailFormValues>()

  const emailFieldPlaceholder = t('enterYourEmail')

  const [editing, setEditing] = React.useState(false)

  if (canChangeEmail && editing) {
    return (
      <TextInput
        name='email'
        label={t('emailLabel')}
        testId='email-input'
        placeholder={emailFieldPlaceholder}
        defaultValue={email}
        errorText={errors.email}
        invalid={!!errors.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    )
  } else {
    return (
      <Panel noElevation>
        <div className='flex '>
          <div className='flex-none'>
            <div className='font-bold'>{t('toastLogin')}</div>
            <div data-testid='email-display'>{email}</div>
          </div>
          <div className='flex-auto text-right'>
            {canChangeEmail && (
              <IconButton
                className='bg-white'
                icon={<EditIcon accessibility='decorative' />}
                onClick={() => setEditing(true)}
              />
            )}
          </div>
        </div>
      </Panel>
    )
  }
}
