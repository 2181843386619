import * as React from 'react'
import { Layout } from '../layout'
import { useTranslation } from '@local/translations'
import {
  ContentHeader,
  PageAside,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'

export const ConfirmEmailView = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <PageMain>
        <ContentHeader
          title={t('email.confirm.heading')}
          description={t('email.confirm.body')}
          contentDivider={true}
        />
      </PageMain>
      <PageAside></PageAside>
    </Layout>
  )
}
