import React from 'react'
import { Layout } from '../layout'
import { Logo } from '../logo'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '@local/translations'
import {
  ContentFooter,
  ContentHeader,
  NavigationContainer,
  PageBreak,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'

export const EmailConfirmedView = (props: {
  email: string
  onContinue: () => void
}) => {
  const { email, onContinue } = props
  const { t } = useTranslation()

  return (
    <Layout>
      <PageMain>
        <ContentHeader title={t('email.confirmed.emailVerified')}>
          <span className='font-bold' data-testid='email'>
            {email}
          </span>{' '}
          {t('email.confirmed.isReadyToBeUsed')}
          <PageBreak />
        </ContentHeader>
        <ContentFooter>
          <NavigationContainer>
            <Button onClick={onContinue} type='button' testId='continue'>
              {t('continue')}
            </Button>
          </NavigationContainer>
        </ContentFooter>
      </PageMain>
    </Layout>
  )
}
