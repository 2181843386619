import { useMutation } from '@tanstack/react-query'
import deferMigration from '../helpers/deferMigration'

const useDeferMigrationMutation = () => {
  const mutation = useMutation({
    mutationFn: async (variables: { returnUrl: string | null }) => {
      return deferMigration(variables.returnUrl)
    }
  })

  return mutation
}

export default useDeferMigrationMutation
